<template>
  <!--Sponsor-->
  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="box p-4">
            <h2 class="title text-center mb-4">Sponsorlar</h2>
            <h5 class="text-center mb-4">Atölye Genel Sponsorları</h5>
            <div class="row custom-gutter">
              <div class="col-md-6 mb-4">
                <img src="@/assets/images/Group 11.png" alt="" />
              </div>
              <div class="col-md-6 mb-4">
                <img src="@/assets/images/Group 13.png" alt="" />
              </div>
            </div>
            <h5 class="text-center mb-4">Teknoloji Sponsorları</h5>
            <div class="row custom-gutter mt-3">
              <div class="col-md-6" style="text-center">
                <img src="@/assets/images/Group 12.png" alt="" />
              </div>
              <div class="col-md-6" style="text-center">
                <img src="@/assets/images/Group 14.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>
