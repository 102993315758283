<template>
  <!--Header Banner-->
  <section class="header-banner">
    <img
      src="@/assets/images/rader-hero.jpg"
      alt="Rader Dijital Güz Atölyesi"
    />
  </section>
</template>

<script>
export default {

};
</script>

<style>
</style>
